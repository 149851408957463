import PageBody, { ViewName } from 'views/PageBody';
import TopIcons from './primitives/TopIcons';
import Wrapper from './primitives/Wrapper';
import { BodyText, Button, HelpLink } from './primitives/Text';

const iHeartHelpLink = 'https://help.iheart.com';

export default function MetaWearablesLink() {
  return (
    <PageBody dataTest={ViewName.MetaWearablesLink} noAds>
      <Wrapper>
        <TopIcons success={false} />
        <BodyText role="heading">Uh-oh. Sorry, We Ran Into an Issue</BodyText>
        <a href="https://www.iheart.com">
          <Button role="button">Go Back to the iHeart App</Button>
        </a>
        <HelpLink role="button" to={iHeartHelpLink}>
          I need help
        </HelpLink>
      </Wrapper>
    </PageBody>
  );
}
